import React from "react";
import moment from "moment";


export const COLUMNS = [
  {
    title: "TimeStamp",
    field: "time",
    type: "datetime",
    defaultSort: "desc",
    render: rowData => <>{(moment.utc(rowData['time']).local().format("YYYY-MM-DD HH:mm:ss"))}</>
  },
  {
    title: "From",
    field: "from_",
    type: "string"
  },
  {
    title: "Path",
    field: "path",
    type: "string"
  },
  {
    title: "Errore",
    field: "error",
    type: "string"
  },
  {
    title: "Stack",
    field: "stack",
    type: "string",
    render: rowData => <div
      style={{maxHeight: 100, maxWidth: 200,  overflow: "scroll", fontSize: 10}}
    >
      {rowData['stack']}
    </div>
  },
  {
    title: "Agent",
    field: "agent",
    type: "string",
    cellStyle: {textAlign: "center", fontSize: 10}
  }
]

export const OPTIONS = {
  filtering: true,
  toolbar: false,
  paging: true,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 15},
  filterCellStyle: {maxWidth: 200},
  cellStyle: {fontSize: 12},
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  },
  body: {
    emptyDataSourceMessage: <h1>Non ci sono errori nella data selezionata (Yay!) 😊</h1>
  }
};