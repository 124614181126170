function detectBrowser() {
  let browserName = "";
  const userAgent = navigator.userAgent;
  if (/Edge\/\d+/.test(userAgent)) {
    browserName = "Microsoft Edge";
  } else if (/MSIE|Trident/.test(userAgent)) {
    browserName = "Microsoft Internet Explorer";
  } else if (/Chrome/.test(userAgent)) {
    browserName = "Google Chrome";
  } else if (/Firefox/.test(userAgent)) {
    browserName = "Mozilla Firefox";
  } else if (/Safari/.test(userAgent)) {
    browserName = "Apple Safari";
  }
  return browserName;
}

function detectMobile() {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );
}

function detectPlatform() {
  let platform = "";
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Win") !==-1) {
    platform = "Windows";
  } else if (userAgent.indexOf("Mac") !==-1) {
    platform = "MacOS";
  } else if (
    userAgent.indexOf("X11") !==-1 ||
    userAgent.indexOf("Linux") !==-1
  ) {
    platform = "Linux";
  }
  return platform;
}

function detectVersion() {
  let version = "";
  const userAgent = navigator.userAgent;
  if (/Edge\/\d+/.test(userAgent)) {
    version = /Edge\/(\d+)/.exec(userAgent)[1];
  } else if (/MSIE|Trident/.test(userAgent)) {
    version = /(?:MSIE |rv:)(\d+)/.exec(userAgent)[1];
  } else if (/Chrome/.test(userAgent)) {
    version = /Chrome\/(\d+)/.exec(userAgent)[1];
  } else if (/Firefox/.test(userAgent)) {
    version = /Firefox\/(\d+)/.exec(userAgent)[1];
  } else if (/Safari/.test(userAgent)) {
    version = /Version\/(\d+)/.exec(userAgent)[1];
  }
  return version;
}

export { detectBrowser, detectMobile, detectPlatform, detectVersion };
