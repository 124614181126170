export const dataFaqIT = [
  {
    question: "A cosa serve questa pagina?",
    answer: "Su questa pagina è possibile segnalare problemi tramite il pulsante APRI TICKET, richiedendo così il supporto di uno dei nostri sviluppatori. Se una certa richiesta diventa molto frequente, allora la inseriremo in questa lista di FAQ (Frequently Asked Questions), così che diventi sempre più semplice per l'utente orientarsi fra le pagine di questo applicativo.",
  }
];


export const dataFaqEN = [
  {
    question: "What is this page for?",
    answer: "Use this page to report problems via the OPEN TICKET button, thus requesting the support of one of our developers. If a certain request becomes very frequent, then we will insert it in this FAQ (Frequently Asked Questions) list, so that it becomes easier for the user to find his way around the pages of this application.\n",
  }
];
