import React from "react";
import CheckboxGroup from "../../CheckboxGroup";
import { useIntl } from "react-intl";

const SelectPolicies = ({ policies, scopes, setScopes }) => {
  const intl = useIntl();
  return Object.keys(policies).map((key) => (
    <div key={key}>
      <CheckboxGroup
        labelClasses="font-bold"
        horizontal
        title={intl.formatMessage({ id: policies[key].description })}
        options={policies[key].scopes.map((o) => ({
          value: o,
          name: o,
        }))}
        values={scopes}
        onChange={(value) => {
          if (scopes.indexOf(value) >= 0) {
            setScopes([...scopes.filter((v) => v !== value)]);
          } else {
            setScopes([...scopes, value]);
          }
        }}
      />
    </div>
  ));
};

export default SelectPolicies;
