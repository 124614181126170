import React from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import {getColumns, LOCALIZATION, OPTIONS} from "./Params/LogBackendDetailParams";
import {tableIcons} from "../../ui-components/TableIcons";
import TableStyle from "../../ui-components/TableStyle";

function LogBackendDetailTable({data}) {

  const COLUMNS = data ? getColumns(data) : [];


  return (
    <TableStyle>
      <MaterialTable
        icons={tableIcons}
        columns={COLUMNS}
        data={data.map((value, index) => ({
          ...value,
          id: index,
          path_params_str: JSON.stringify(value['path_params']),
          query_params_str: JSON.stringify(value['query_params_str'])
        }))}
        options={OPTIONS}
        title={"Backend"}
        localization={LOCALIZATION}
      />
    </TableStyle>
  );
}

export default React.memo(LogBackendDetailTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.data, nextProps.data);
});
