import React from "react";
import moment from "moment";

export const COLUMNS = [
  {
    title: "Timestamp",
    field: "timestamp",
    type: "datetime",
    defaultSort: "desc",
    render: rowData => <>{(moment.utc(rowData['timestamp']).local().format("YYYY-MM-DD HH:mm:ss"))}</>
  },
  {
    title: "Utente",
    field: "user",
    type: "string",
    render: rowData => <div style={{maxWidth: 150, overflowX: "scroll"}}>{rowData['user']}</div>
  },
  {
    title: "Pagina",
    field: "page",
    type: "string",
  },
  {
    title: "Sistema operativo",
    field: "platform",
    type: "string",
  },
  {
    title: "Browser",
    field: "browser",
    type: "string",
    render: rowData => <>{`${rowData['browser']} ${rowData['browser_version'] ? "v" + rowData['browser_version'] : ""}`}</>
  },
  {
    title: "Mobile",
    field: "mobile",
    type: "boolean",
  },
]

export const OPTIONS = {
  filtering: true,
  toolbar: false,
  paging: true,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 15, zIndex: 0},
  filterCellStyle: {maxWidth: 200},
  cellStyle: {fontSize: 12},
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  }
};