// Api URL based on env
export const API_URL = "/api";

export const ROLES = [
  {value: "admin", label: "Admin"},
  {value: "user", label: "Utente base"}
]

export const CALENDAR_MODE = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly"
}