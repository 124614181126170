import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import AuthenticationService from "../../services/AuthenticationService";

const PrivateRoute = ({
  component: Component,
  scopes: pageScopes,
  ...rest
}) => {
  const { pathname } = useLocation();

  if (!AuthenticationService.isUserAuthenticated()) {
    return <Redirect to={{ pathname: "/login", state: { from: pathname } }} />;
  }

  const scopes = AuthenticationService.getUserScopes();

  if (pageScopes && !pageScopes.some((r) => scopes.indexOf(r) >= 0)) {
    return <Redirect to={{ pathname: "/home", state: { from: pathname } }} />;
  }

  return <Route {...rest} component={Component} />;
};

export default PrivateRoute;
