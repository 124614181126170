import React from "react";

const Placeholder = ({ classNames = "", width = "w-full", height = "h-5" }) => {
  return (
    <div
      data-placeholder
      className={`mb-2 rounded-3xl overflow-hidden relative bg-gray-200 ${height} ${width} ${classNames}`}
    />
  );
};

export default Placeholder;
