// EXAMPLE => fake public route import
// import FakePublicPage from "@pages/FakePublicPage";

const publicRoutes = [
  // EXAMPLE => fake public route definition
  // {
  //   name: "fakepublicpage",
  //   href: "/fake-public-page",
  //   component: FakePublicPage
  // },
];

export default publicRoutes;

