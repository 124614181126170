import React from "react";
import moment from "moment";
import _ from "lodash";

export const getColumns = (data) => [
  {
    title: "Timestamp",
    field: "timestamp",
    type: "datetime",
    defaultSort: "desc",
    render: rowData => <>{(moment(rowData['timestamp']).format("YYYY-MM-DD HH:mm:ss"))}</>
  },
  {
    title: "Utente",
    field: "user",
    type: "string",
    render: rowData => <div style={{maxWidth: 150, overflowX: "scroll"}}>{rowData['user']}</div>
  },
  {
    title: "Metodo",
    field: "method",
    lookup: Object.values(data).reduce((obj, i) => _.set(obj, [i['method']], i['method']), {})
  },
  {
    title: "Route",
    field: "route",
    type: "string",
  },
  {
    title: "Status",
    field: "status_code",
  },
  {
    title: "Path Params",
    field: "path_params_str",
    type: "string",
  },
  {
    title: "Query Params",
    field: "query_params_str",
    type: "string",
  },
  {
    title: "IP",
    field: "ip",
    type: "string",
  },
]

export const OPTIONS = {
  filtering: true,
  toolbar: false,
  paging: true,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 15, zIndex: 0},
  filterCellStyle: { maxWidth: 200},
  cellStyle: {fontSize: 12},
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  }
};