const en_faq = {
    support:"Support",
    faq:"Frequently Asked Questions",
    button_faq: "Open Ticket",
    modal_title: "Open a ticket to get support from our customer service",
    type_problem: "Type of problem",
    measure_problem: "How much does the problem encountered affect your work?",
    subject_problem: "Subject",
    describe_problem: "Describe your problem",
    attachment: "Optional attachments",
    send_problem: "Send",
    error_message: "Fill the required field.",
    risk_one: "I can keep working without any problem, it's just a discomfort",
    risk_two: "It impedes my work quite a bit, but I can keep working.",
    risk_three: "Can't work, to be fixed immediately.",
    category_one:"I do not access the service / the page is not available",
    category_two: "I didn't receive the results",
    category_three: "The results provided are different from what was expected",
    category_four: "The system crashed",
    category_five: "Other",
    missing_data: "Missing data",
    success: "Item added correctly",
    select:"Select an option"
}

export default en_faq;

