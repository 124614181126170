import React, {useState} from "react";
import Label from "./Label";
import {ExclamationCircleIcon} from "@heroicons/react/24/solid";


const InputMultiLine = ({
                          value,
                          label,
                          name,
                          required,
                          errorMessage,
                          ...rest
                        }) => {
  const [valid, setValid] = useState(true);
  let inputValue = value || "";

  return (
    <div className="mb-1">
      {label ? <Label>{label}</Label> : null}
      <div className="mt-1 relative rounded-md shadow-sm">
          <textarea
            onFocus={() => {
              setValid(true);
            }}
            onBlur={(e) => {
              if (required) {
                if (!e.target.value) {
                  setValid(false);
                  return;
                }
                setValid(true);
              }
            }}
            className={`px-2 py-2 pr-10 border shadow-sm focus:ring-am-500 focus:border-am-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50`}
            value={inputValue}


            {...rest}
          />
        {!valid ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {!valid && errorMessage ? (
        <p className="mt-1 text-xs text-red-600" id="email-error">
          {errorMessage}
        </p>
      ) : (
        <div className="mt-2 h-2"/>
      )}
    </div>
  );
};

export default InputMultiLine;