import React, {useState, useEffect} from "react";

// Services
import { useIntl } from "react-intl";

// UI
import { PageSection } from "../../Container";

import { dataFaqEN, dataFaqIT } from "../../../utils/faqConstants";
import SingleFaq from "./SingleFaq";
import Placeholder from "../../../ui-components/Placeholder";

function FaqList() {
  const intl = useIntl();
  const dataFaq = (localStorage.getItem("lang") === "it") ? dataFaqIT : dataFaqEN;
  const [renderFaq, setRenderFaq] = useState(true)

  function debounce(ms) {
    let timer;
    return () => {
      setRenderFaq(false)
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        setRenderFaq(true)
      }, ms)
    };
  }

  useEffect(() => {
    // re-render of every FAQ when window width value changes, to correctly refresh opening animation
    window.addEventListener('resize', debounce(300))

    return () => {
      setRenderFaq(false);
    }
  }, [])

  return (
    <div>
      <PageSection
        className="w-full"
        title={intl.formatMessage({id: "faq"})}
      />
      <div className="p-5 relative rounded-lg shadow-md">
        <div className="list-inside">
          {dataFaq.map((element, index) => (
            <div key={element.answer} >
              {renderFaq ? <SingleFaq answer={element.answer} question={element.question} /> : <Placeholder height="h-3"/>}
              {/* check, if last element does not render separator line */}
              {dataFaq.length !== index + 1 && <div className="mt-4 mb-4 w-full border-t border-gray-300" />}
            </div>))
          }
        </div>
        <br/>
      </div>
    </div>
  );
}

export default FaqList;
