import React from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

import {COLUMNS, LOCALIZATION, OPTIONS} from "./Params/LogFrontendDetailParams";
import {tableIcons} from "../../ui-components/TableIcons";
import TableStyle from "../../ui-components/TableStyle";

function LogFrontendDetailTable({data}) {

  return (
    <TableStyle>
      <MaterialTable
        icons={tableIcons}
        columns={COLUMNS}
        data={data.map((value, index) => ({...value, id: index}))}
        options={OPTIONS}
        title={"Frontend"}
        localization={LOCALIZATION}
      />
    </TableStyle>
  )
}

export default React.memo(LogFrontendDetailTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.data, nextProps.data);
});
