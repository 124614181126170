import React from "react";
import Spinner from "../../Spinner";
import usePolicies from "./Hooks/usePolicies";
import SelectPolicies from "./SelectPolicies";

const UpdateScopes = ({ value, onChange }) => {
  const policies = usePolicies();

  if (!policies) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <SelectPolicies
      policies={policies}
      scopes={value ? value.split(";") : []}
      setScopes={(e) => onChange(e.length ? e.join(";") : "")}
    />
  );
};

export default UpdateScopes;
