import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addCursor, addLicense, getXYChart} from "./commons";


function StackedColumnChart({id, data, dimensions, chartHeight, filter = "", aggregation = "giorno"}) {

  useLayoutEffect(() => {

    if (!data)
      return

    const xAxisTimeSpan = aggregation === "giorno" ? "day" : "month"

    addLicense();
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    let chart = getXYChart(root, {layout: root.verticalLayout})
    addCursor(root, chart)

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );

    legend.markers.template.setAll({
      width: 10,
      height: 10
    });

    legend.labels.template.setAll({
      fontSize: 10,
      fontWeight: "300"
    });

    // Create axes
    // CategoryAxis: is the only axis type that requires its own data as well as data field set up.
    let xAxis = chart.xAxes.push(
      chart.xAxes.push(am5xy.DateAxis.new(root, {
          baseInterval: {timeUnit: xAxisTimeSpan, count: 1},
          gridIntervals: [
            {timeUnit: "day", count: 1}],
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      ))
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      renderer: am5xy.AxisRendererY.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }))

    // Add series
    function makeSeries(name) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: name,
        valueXField: "date",
        stacked: filter !== 'unique_visitors',

      }))

      series.columns.template.setAll({
        width: am5.percent(60)
      });

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        tooltipY: am5.percent(10)
      });
      series.data.setAll(data);

      series.appear(1000);

      legend.data.push(series);
    }

    dimensions.map(el => makeSeries(el))

    return () => {
      root.dispose()
    }
  },
  [id, data, dimensions, filter] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} style={{minWidth: "200px", minHeight: chartHeight}}/>
}

export default React.memo(StackedColumnChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
