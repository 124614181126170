import { detectBrowser, detectVersion, detectMobile, detectPlatform } from "./detectDeviceInfo";

/**
*   IMPORTANT READ CAREFULLY
*   userAgentData is still experimental (23/02/2023)
*   Safari and Firefox don't support userAgentData at the current date, in this case browser variable will be null
*   as a fallback window.navigator.userAgent is used
*   userAgent is unreliable since it can be easily spoofed and it's hard to cover all cases because many
*   browser/device combinations have a custom userAgent string
**/
const createFrontendLog = (location) => {
    let logging = {}

    const loggingInfo = window.localStorage.logged_user ? JSON.parse(window.localStorage.logged_user) : null;
    let device = window.navigator.userAgentData;
    let browser = device?.brands?.length > 0 ? device.brands[2] : null;

    // Log information on page change
    // PAGE
    logging['page'] = location.pathname;

    if(browser !== null){
        //for browsers that support userAgentData
        logging['browser'] = browser?.brand;
        logging['browser_version'] = parseFloat(browser?.version);
        logging['mobile'] = device?.mobile;
        logging['platform'] = device?.platform;
    }
    else{
        //fallback
        device = window.navigator.userAgent;
        logging['browser'] = detectBrowser(device);
        logging['browser_version'] = detectVersion(device);
        logging['mobile'] = detectMobile(device);
        logging['platform'] = detectPlatform(device);
    }
    //other info independent from userAgentData/userAgent
    logging['user'] = loggingInfo?.email;
    logging['screen_width'] = window.innerWidth;
    logging['screen_height'] = window.innerHeight;
    logging['touch'] = window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;
    logging['user_agent'] = window.navigator.userAgent;

    return logging;
}

export default createFrontendLog;