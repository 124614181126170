import React, {useCallback} from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

// Components
import Calendar from "react-calendar";
import Label from "./Label";

// Language
import {localeMapper} from "../locale";

// Constants
import {CALENDAR_MODE} from "../utils/constants";
const today = moment().startOf("day").toDate();
const tomorrow = moment(today).add(1, "day").toDate();


const getDaysOfSelectedWeek = (date, minDate, maxDate, selectableDates) => {
  let arr = [];
  let day = 0;
  while (day < 7) {
    // to remove ambiguity on week, have to specify year and month
    const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + day++);

    // if maxDate or selectableDates are not specified, condition is automatically true
    if (
      newDate >= minDate &&
      (maxDate ? newDate <= maxDate : true) &&
      (selectableDates ? selectableDates.find((x) => getStringDate(x) === getStringDate(newDate)) : true)
    )
      arr.push(newDate);
  }
  return arr;
};

const getFirstOfTheYear = (week) => {
  const first = week.filter(element => element.getDate() === 1);
  return first[0] ?? week[0];
}

const getStringDate = (date) => {
  return date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : "";
}

const dateAlreadyClicked = (dates, date) =>
  dates.map((d) => getStringDate(d)).indexOf(getStringDate(date)) >= 0;

const monthAlreadyClicked = (dates, date) => {
  return dates ? date.getFullYear() === dates.getFullYear() && date.getMonth() === dates.getMonth() : false;
}

const getNewDate = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);

  return d;
};

const CalendarInput = ({
  label,
  dates,
  setDates,
  minDate = tomorrow,
  maxDate = null,
  selectableDates = null,
  calendarStartDate = today,
  mode = CALENDAR_MODE.DAILY,
  single,
  view,
}) => {
  const tileClassName = useCallback(
    ({date, view}) => {
      switch (mode) {
        case CALENDAR_MODE.WEEKLY:
          if (view === "year") return null
          if (dateAlreadyClicked(getDaysOfSelectedWeek(calendarStartDate, minDate, maxDate, selectableDates), new Date(date))
            && dates)
            return "day-selected";
          return "active-day-not-selectable";
        case CALENDAR_MODE.MONTHLY:
          if (monthAlreadyClicked(dates, date))
            return "day-selected";
          return null;
        default:
          if (dateAlreadyClicked(dates, new Date(date))) return "day-selected";
          return null;
      }
    },
    [dates] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className="flex flex-col" tabIndex="0">
      {label && (
        <>
          <Label>{label}</Label>
          <div className="mb-4"/>
        </>
      )}
      <Calendar
        className={["shadow bg-gray-50 rounded-3xl px-4 py-6 border-0"]}
        tileClassName={tileClassName}
        onClickMonth={(e) => {
          if (mode === CALENDAR_MODE.MONTHLY) {
            setDates(e)
          }
        }}
        onClickDay={(e) => {
          if (mode !== CALENDAR_MODE.DAILY) return;
          const clickDate = getNewDate(e);

          if (single) {
            setDates([clickDate]);
            return;
          }
          if (
            dates
              .map((d) => getStringDate(d))
              .indexOf(getStringDate(clickDate)) >= 0
          ) {
            setDates([
              ...dates.filter(
                (d) => getStringDate(d) !== getStringDate(clickDate)
              ),
            ]);
            return;
          }
          setDates([...dates, clickDate]);
        }}
        minDate={minDate}
        maxDate={maxDate}
        locale={localeMapper[localStorage.getItem("lang")] ?? "en-EN"}
        tileDisabled={(tile) => {
          if (!selectableDates) return null; // passthrough: the tile is enabled
          return !selectableDates.find(
            (d) => d.toDateString() === tile.date.toDateString()
          );
        }}
        onClickWeekNumber={(week, day) => {
          // checks also that first day of week is a valid day
          const arrayOfDays = getDaysOfSelectedWeek(day, minDate, maxDate, selectableDates);
          if (
            mode === CALENDAR_MODE.WEEKLY &&
            arrayOfDays.length !== 0
          ) {
            const firstDay = week === 1 ? getFirstOfTheYear(arrayOfDays) : day;
            const year = firstDay.getFullYear();    // get correct year for week 1
            setDates({'year': year, 'week': week, firstDay });
          }
        }}
        view={view}
        defaultActiveStartDate={calendarStartDate}
        showWeekNumbers={mode === CALENDAR_MODE.WEEKLY}
        week
      />
    </div>
  );
};

export default CalendarInput;
